// firebase/index
export const firebaseConfig = {
	apiKey: "AIzaSyAzhYVLdDYI6yUid6cQMID2ddLMU1Djtc0",
	authDomain: "etifvirtual.firebaseapp.com",
	projectId: "etifvirtual",
	storageBucket: "etifvirtual.appspot.com",
	messagingSenderId: "62016034407",
	appId: "1:62016034407:web:220172756e0a433bbf66c2",
	measurementId: "G-V5RZDNDTBZ"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/etifvirtual.appspot.com/o/'